import {
  CONVEYANCE_TYPES,
  removePickupTime,
  setPickupTime,
  getPickupTimeByBasket,
  getPickupTime,
} from '@koala/sdk/v4';
import { useMutation, useQuery } from '@tanstack/react-query';
import { isToday, parseISO } from 'date-fns';
import { getMappedConveyanceType } from './utils';
import { genericEventHandler } from '@/analytics/events';
import { GlobalEvents } from '@/analytics/events/constants';
import { ERROR_MESSAGES, K_ANALYTICS_EVENTS } from '@/constants/events';
import { DELIVERY_TIME_WANTED_MODES } from '@/constants/global';
import { useDispatch, useSelector } from '@/redux';
import basketActions from '@/redux/basket/actions';
import { BASKET_ERROR } from '@/redux/basket/messages';
import conveyanceModeActions from '@/redux/conveyanceMode/actions';
import orderStatusActions from '@/redux/orderStatus/actions';
import { createHttpClient } from '@/services/client';
import { getOrigin } from '@/utils';
import { prepareErrorMessage } from '@/utils/global';
import { fireGaEvent, gaActions, gaCats } from '@/utils/googleAnalytics';
import { fireKAnalyticsError, fireKAnalyticsEvent } from '@/utils/koalaAnalytics';

interface HandoffTimesInput {
  /** ID of the staged basket, if available. */
  basketId?: string;
  /** Desired pickup day in ISO8601 format. */
  dayWanted: string;
  /** The order's handoff type. */
  handoffType: CONVEYANCE_TYPES;
  /** Store location ID. */
  locationId: number;
  /** Does the location support ASAP handoff? */
  supportsAsap: boolean;
}

/** Retrieves a list of available handoff times for a basket or location. */
export async function getHandoffTimes(input: HandoffTimesInput) {
  const hoursType =
    input.handoffType === CONVEYANCE_TYPES.DELIVERY ? 'delivery_hours' : 'operating_hours';

  let res: string[];
  const origin = getOrigin(window.location.host);
  const client = createHttpClient({ origin });

  // If a Basket ID is provided, fetch handoff times for that basket.
  if (input.basketId) {
    res = await getPickupTimeByBasket(
      {
        basketId: input.basketId ?? '',
        locationId: input.locationId,
        hoursType,
        dayWanted: input.dayWanted,
      },
      { client },
    );
  } else {
    // Otherwise, look up pickup times for the location.
    res = await getPickupTime(
      {
        locationId: input.locationId,
        wantedAtType: hoursType,
        dayWanted: input.dayWanted,
      },
      { client },
    );
  }

  /**
   * If the user wants to pick up today and the location supports
   * ASAP, prepend it to the list of available handoff times.
   *
   * NOTE: Other places in the codebase we use isStoreCurrentlyOpen(operatingHours?: LocationOperatingHours[]) function to determine if store is open
   */
  if (input.supportsAsap && isToday(parseISO(input.dayWanted))) {
    return [DELIVERY_TIME_WANTED_MODES.ASAP, ...res];
  }

  // If there are no times available, the store is likely closed on a given day.
  if (res?.length === 0) {
    return res;
  }

  // Otherwise, return the list of times as-is.
  return res;
}

export function useHandoffTimes(input: HandoffTimesInput) {
  return useQuery({
    queryKey: ['handoffTimes', input],
    queryFn: () => getHandoffTimes(input),
    refetchOnWindowFocus: false,
    staleTime: 3600000, // 60 minutes
  });
}

interface SetHandoffTimeInput {
  /** ID of the staged basket, if available. */
  basketId: string;
  /** Store location ID. */
  locationId: number;
  /** Selected handoff time. */
  handoffTime: string;
}

async function setHandoffTime(input: SetHandoffTimeInput) {
  const origin = getOrigin(window.location.host);
  const client = createHttpClient({ origin });

  if (input.handoffTime === DELIVERY_TIME_WANTED_MODES.ASAP || !input.handoffTime) {
    const res = await removePickupTime(
      { basketId: input.basketId, locationId: input.locationId },
      { client },
    );
    return res;
  }

  const res = await setPickupTime(
    {
      basketId: input.basketId,
      locationId: input.locationId,
      wanted_at: input.handoffTime,
    },
    { client },
  );
  return res;
}

export function useSetHandoffTime() {
  const { checkoutBasket } = useSelector((state) => state.app.basket);
  const dispatch = useDispatch();
  return useMutation(setHandoffTime, {
    // Trigger analytics events when the mutation is fired.
    onMutate: (input) => {
      if (input.handoffTime === DELIVERY_TIME_WANTED_MODES.ASAP) {
        fireGaEvent(gaCats.order, gaActions.asapTimeSelected);
      } else {
        fireGaEvent(gaCats.order, gaActions.pickupTimeSelected);
        genericEventHandler(GlobalEvents.WANTED_AT_SELECTED, {
          name: checkoutBasket?.conveyance_type?.type.toLowerCase(),
          details: input.handoffTime,
        });
      }
      return { handoffTime: input.handoffTime };
    },
    // Surface any errors that occurred when setting the handoff time.
    onError: async (e) => {
      const err = await prepareErrorMessage(BASKET_ERROR.setPickupTimeSaga, e);
      dispatch(orderStatusActions.orderStatusMessageErrorSet(err.message));
      fireKAnalyticsError(ERROR_MESSAGES.SET_PICKUP_TIME_ERROR, e, err);
    },
    // Dispatch actions to sync up Redux with the updated basket.
    onSuccess: (basket, variables) => {
      dispatch(orderStatusActions.orderPending());
      dispatch(basketActions.success(basket));
      dispatch(
        conveyanceModeActions.setWantedTime(
          basket.wanted_at !== null ? variables.handoffTime : undefined,
          Number(basket.location.id),
          getMappedConveyanceType(basket.conveyance_type.type),
        ),
      );
      fireKAnalyticsEvent(K_ANALYTICS_EVENTS.BASKET_WANTED_AT_UPDATE);
    },
  });
}
